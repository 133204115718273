import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DOMAIN_COMPANY } from '@app/data/constants/domains';
import { ROLE } from '@app/data/enums/role';
import { ICompanyUser, IUser } from '@models/IUser';
import { DatabaseProvider } from '@paella-front/ngx-database';
import { Database } from '@paella-front/ngx-database/lib/database';
import { SuperHttpClientProvider } from '@paella-front/ngx-super-http-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerErrorService } from './server-error.service';
import { environment } from '@env';

const CURRENT_USER = 'current_user';
const CURRENT_ROL = 'current_rol';
const CURRENT_COMPANY_USER = 'current_company_user';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private user: IUser = null;
  private role: ROLE = null;
  private _user$: BehaviorSubject<IUser> = new BehaviorSubject(null);
  private _role$: BehaviorSubject<ROLE> = new BehaviorSubject(null);
  private _companyUser$: BehaviorSubject<ICompanyUser> = new BehaviorSubject(null);
  private readonly $ehttp: ReturnType<SuperHttpClientProvider['use']>;

  /////////////////
  // Constructor //
  /////////////////

  constructor(
    private $db: DatabaseProvider,
    $$shttp: SuperHttpClientProvider,
    public $serverError: ServerErrorService,
    private router: Router
  ) {
    this.$ehttp = $$shttp.use(DOMAIN_COMPANY);
  }

  ///////////////////////
  // Private Accessors //
  ///////////////////////

  ///////////////////////
  // Public Accessors //
  ///////////////////////

  getErrorResponse(errorResponse: HttpErrorResponse): string {
    if (
      errorResponse.error &&
      errorResponse.error.length > 0 &&
      errorResponse.error[0].message
    ) {
      return errorResponse.error[0].message;
    } else if (errorResponse.error && Object.keys(errorResponse.error).length > 0) {
      return errorResponse.error[Object.keys(errorResponse.error)[0]];
    }
    return 'Ocurrió un error inesperado';
  }

  getUser(): IUser | null {
    return this.user;
  }

  async getStoredUser(): Promise<IUser> {
    return await (this.$db.default as Database).get(CURRENT_USER);
  }

  async getStoredRole(): Promise<ROLE> {
    return await (this.$db.default as Database).get(CURRENT_ROL);
  }

  get user$(): Observable<IUser> {
    return this._user$.asObservable();
  }

  get role$(): Observable<ROLE> {
    return this._role$.asObservable();
  }

  get companyUser$(): Observable<ICompanyUser> {
    return this._companyUser$.asObservable();
  }

  setUser$(_user: IUser | null, store?: boolean): void {
    this.user = _user;
    if (store) {
      (this.$db.default as Database).set(CURRENT_USER, _user);
    } else {
      (this.$db.default as Database).remove(CURRENT_USER);
    }
    this._user$.next(_user);
  }

  setRole$(_role: ROLE, store?: boolean): void {
    if (store) {
      (this.$db.default as Database).set(CURRENT_ROL, _role);
    } else {
      (this.$db.default as Database).remove(CURRENT_ROL);
    }
    if (!environment.machineryGUI) {
      this.manageRedirectionEmployee(_role);
    }
    this._role$.next(_role);
  }

  setCompanyUser$(_companyUser: ICompanyUser, store?: boolean): void {
    if (store) {
      (this.$db.default as Database).set(CURRENT_COMPANY_USER, _companyUser);
    } else {
      (this.$db.default as Database).remove(CURRENT_COMPANY_USER);
    }
    this._companyUser$.next(_companyUser);
  }

  getMe(): Observable<IUser> {
    return this.$ehttp.get(`/user/me`).pipe(
      map((user: IUser) => {
        this.setUser$(user, true);
        return user;
      })
    );
  }

  removeFile(
    type: string,
    idFileReference: number,
    idFile: number,
    idEstab?: number
  ): Observable<unknown> {
    const url =
      type === 'estab'
        ? `/estab/${idEstab}/${idFileReference}/file/${idFile}`
        : `/ref/${idFileReference}/file/${idFile}`;
    return this.$ehttp.delete<unknown>(url);
  }

  orderFiles(
    type: string,
    data: { idFileReference: number; idFileList: unknown[]; sortOrderList: unknown[] }
  ): Observable<unknown> {
    return this.$ehttp.put<unknown>(`/${type}/orderFiles`, data);
  }

  private manageRedirectionEmployee(_role: ROLE): void {
    if (!this.role && (_role === ROLE.MANAGER || _role === ROLE.EMPLOYEE)) {
      this.router.navigate(['/', 'hour-tracking', 'list']);
    }
    this.role = _role;
  }
}
